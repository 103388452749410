.flexContainer {
  display: flex;
  justify-content: center;
}

.sectionContainer {
  max-width: 1320px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 15px;
}

.paddingContainer,
.paddingContainerBelow {
  display: flex;
  justify-content: center;
  width: 100%;
  /* padding: 0px 15px; */
}
.paddingContainerBelow {
  padding: 0px;
}

.titleContainer,
.titleContainerBelow {
  margin-top: var(--spacing88);
  padding-bottom: var(--spacing48);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
  border-bottom: 1px solid var(--colorGrey);
  width: 100%;
}

.titleContainerSmallSpacing {
  margin-top: var(--spacing12)
}

@media (--desktop) {
.titleContainerSmallSpacing {
    margin-top: var(--spacing48)
  }
}

.noBorder {
  border-bottom: initial;
}

.titleContainerBelow {
  margin-top: var(--spacing48);
}

.title,
.titleBelow {
  font-weight: var(--fontWeightBold);
}

.titleBelow {
  font-size: var(--fontSizeM);
  padding-top: var(--spacing16);
}

.title,
.subtitle {
  font-size: var(--fontSizeL);
}

@media (--desktop) {
  .titleBelow {
    padding-bottom: var(--spacing88);
  }
  .sectionContainer {
    max-width: 1320px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 40px;
  }
}
